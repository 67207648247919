import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

// Connects to data-controller="tax-return-selection"
export default class extends Controller {
  static targets = ["entityState"];
  checkedEntityStates = [];

  toggleEntityState(event) {
    const entityState = event.params["entityState"];
    this.updateCheckedEntityStates(entityState, event.currentTarget.checked);
    this.updateEntityStates();
  }

  toggleAllEntityStates(event) {
    this.checkedEntityStates = event.currentTarget.checked ? event.params["entityStates"] : [];
    this.updateAllEntityStates(event.currentTarget.checked);
    this.updateEntityStates();
  }

  getValue(selector){
    const element = document.querySelector(selector);
    return element ? element.value : "";
  };

  params(){
    let params = new URLSearchParams();
    params.set("entity_states", JSON.stringify(this.checkedEntityStates));
    params.set("year", this.getValue("#year"));
    params.set("period", this.getValue("#period"));
    params.set("filing_frequency", this.getValue("#filing_frequency"));
    params.set("end_period", this.getValue("#end_period"));
    params.set("end_year", this.getValue("#end_year"));

    return params.toString()
  }

  updateCheckedEntityStates(entityState, isChecked) {
    if (isChecked) {
      !this.checkedEntityStates.includes(entityState) && this.checkedEntityStates.push(entityState);
    } else {
      const index = this.checkedEntityStates.indexOf(entityState);
      index !== -1 && this.checkedEntityStates.splice(index, 1);
    }
  }

  updateAllEntityStates(isChecked) {
    this.entityStateTargets.forEach(entityState => {entityState.checked = isChecked});
  }

  updateEntityStates() {
    get(this.url(), {responseKind: "turbo-stream"});
  }

  url(){
    return `${this.data.get("url")}?${this.params()}`
  }
}
