import OneSchemaController from './one_schema_controller';
import oneschemaImporter from '@oneschema/importer';
import { post, put } from '@rails/request.js';

// Connects to data-controller="one-schema-multifile"
export default class extends OneSchemaController {
  static targets = ['container', 'month', 'year'];
  static values = {
    uploadUrl: String,
    upsertUrl: String,
    entityId: Number,
    parentCompanyId: Number,
    templateKey: String,
    templateId: Number,
    clientId: String,
    railsEnv: String,
    customizationKey: String,
    eventWebhookKey: String,
    month: Number,
    year: Number,
  };
  connect() {
    // Empty connect as parent class does things we do not want
  }
  containerTargetConnected(element) {
    const token = element.dataset.token;
    const importerInstance = oneschemaImporter(this.configureImporter(token));
    const iframe = element.querySelector('iframe');

    importerInstance.on(
      'launched',
      this.onDropzoneInitialization.bind(
        element,
        this.upsertUrlValue,
        this.templateIdValue,
        this.monthValue,
        this.yearValue,
      ),
    );
    importerInstance.on(
      'success',
      this.onFileImportSuccess.bind(element, this.upsertUrlValue, this.monthValue, this.yearValue),
    );
    importerInstance.setParent(element);
    importerInstance.setIframe(iframe);
    importerInstance.launch({ sessionToken: element.dataset.sessionToken });
  }

  configureImporter(token) {
    return {
      ...super.configureImporter(token),
      customizationKey: this.customizationKeyValue,
      eventWebhookKeys: [this.eventWebhookKeyValue],
      manageDOM: false,
    };
  }

  onDropzoneInitialization(upsertUrlValue, templateId, month, year, data) {
    this.dataset.sessionToken = data.sessionToken;

    post(upsertUrlValue, {
      headers: {
        Accept: 'text/vnd.turbo-stream.html',
      },
      body: JSON.stringify({
        month,
        year,
        financial_data_import_batch_file: { id: data.sessionToken, template_id: templateId },
      }),
    });
  }

  /**
   * On success callback.
   * @param {string} upsertUrlValue - The title of the book.
   * @param {number} month - The author of the book.
   * @param {number} year - The author of the book.
   * @param {object} month - The author of the book.
   */
  async onFileImportSuccess(upsertUrlValue, month, year, context) {
    const id = this.dataset.sessionToken;
    const {
      count: record_count,
      error_count,
      embed_id,
      sheet_metadata: { original_file_name: filename },
    } = context;
    const urlParts = upsertUrlValue.split('?');
    const url = `${urlParts[0]}/${id}`;
    const queryParams = `?${urlParts[1]}`;

    await put(url + queryParams, {
      headers: {
        Accept: 'text/vnd.turbo-stream.html',
      },
      body: JSON.stringify({
        month,
        year,
        financial_data_import_batch_file: {
          embed_id,
          record_count: record_count + error_count,
          filename,
          status: 'completed',
        },
      }),
    });

    this.remove();
  }

  uploaderStyles() {
    return {
      position: 'relative',
      width: '80dvw',
      height: '600px',
      border: '1px dashed #ccc',
    };
  }
}
