import { Controller } from '@hotwired/stimulus';
import TomSelect from 'tom-select';

export default class extends Controller {
  connect() {
    this.select = new TomSelect(this.element, {
      controlClass: 'select-control',
      optionClass: '',
      render: {
        option: (data, escape) => {
          return `
            <div class="flex flex-col items-start p-8">
              <span class="pb-2 text-sm font-medium color-navy-syntax">${escape(data.text)}</span>
              <img src="${data.imageUrl}" class="h-20 w-auto">
            </div>
          `;
        },
      },
    });
  }

  disconnect() {
    this.select.destroy();
  }
}
