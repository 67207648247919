import { Controller } from '@hotwired/stimulus';
import _ from 'lodash-es';

export default class extends Controller {
  static values = {
    debounce: { type: Number, default: 500 },
  };

  connect() {
    this.change = _.debounce(this.change, this.debounceValue).bind(this);
  }

  change() {
    this.element.requestSubmit();
  }
}
