import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="target-modal"
export default class extends Controller {
  static outlets = [ "modal" ]

  open(event) {
    this.modalOutlet.open(event);
  }
}
