import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "year",
    "period",
    "endYear",
    "endPeriod",
    "form",
    "filingFrequency",
    "changePeriodSelector",
  ];

  connect() {
    this.updateSelects();

    this.updateSelectedPeriodOrYear();
  }

  changeFrequency(event) {
    this.changePeriodSelectorTarget.value = true;

    if (this.hasPeriodTarget) {
      this.periodTarget.selectedIndex = -1;
    }

    this.submitForm();
  }

  updateSelects() {
    const frequency = this.filingFrequencyTarget.value;
    const isSemiannually = frequency === 'semiannually';

    if (this.hasEndPeriodTarget && this.hasEndYearTarget) {
      this.endPeriodTarget.disabled = isSemiannually;
      this.endYearTarget.disabled = isSemiannually;
    }
  }

  updateEndPeriodForSemiannually() {
    const selectedMonth = this.hasPeriodTarget ? parseInt(this.periodTarget.value) : 0;
    const selectedYear = this.hasYearTarget ? parseInt(this.yearTarget.value) : new Date().getFullYear();

    if (selectedMonth && selectedYear) {
      const startPeriod = new Date(selectedYear, selectedMonth - 1);
      startPeriod.setMonth(startPeriod.getMonth() + 5);

      const endMonth = startPeriod.getMonth() + 1;
      const endYear = startPeriod.getFullYear();

      this.endPeriodTarget.value = endMonth;
      this.endYearTarget.value = endYear.toString();
    }
  }

  updateSelectedPeriodOrYear() {
    if (this.filingFrequencyTarget.value === 'semiannually') {
      this.updateEndPeriodForSemiannually();
    }
  }

  submitForm() {
    this.formTarget.requestSubmit();
  }
}
