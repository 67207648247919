import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="one-schema-multifile-button"
export default class extends Controller {
  static targets = ["file", "importButton"];

  connect() {
    this.toggleImportButton();
  }

  fileTargetConnected(event) {
    this.toggleImportButton();
  }

  fileTargetDisconnected(event) {
    this.toggleImportButton();
  }

  toggleImportButton() {
    this.importButtonTarget.disabled = this.fileTargets.length === 0;
  }
}
