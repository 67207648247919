import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ["content", "chevron"];

  toggle(event) {
    const content = event.currentTarget.closest(".accordion-item").querySelector(".accordion-content");
    const chevron = event.currentTarget.querySelector(".fa-chevron-down");

    content.classList.toggle("opacity-0");
    content.classList.toggle("hidden");

    chevron.classList.toggle("rotate-180");
  }
}
