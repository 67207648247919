// app/javascript/controllers/sellers_origins_controller.js
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['sellersOrigins', 'columnImported']

  connect() {
    this.toggleColumnImported()
  }

  toggleColumnImported() {
    const simplifiedSelected = this.sellersOriginsTargets.some(checkbox => checkbox.checked && checkbox.value === 'simplified')
    this.columnImportedTargets.forEach(checkbox => {
      checkbox.disabled = !simplifiedSelected
    })
  }
}
