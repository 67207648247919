import { Controller } from '@hotwired/stimulus';
import WebViewer from 'webviewer';

export default class extends Controller {
  static values = { url: String, fieldValues: Object, webviewerKey: String, fileExtension: String };

  async connect() {
    const instance = await WebViewer(
      {
        path: '/webviewer',
        licenseKey: this.webviewerKeyValue,
        initialDoc: this.urlValue
      },
      this.element,
    );

    const { documentViewer, annotationManager } = instance.Core;
    const { FitMode } = instance.UI;

    instance.UI.setFitMode(FitMode.FitWidth);

    documentViewer.addEventListener('documentLoaded', async () => {
      await documentViewer.getAnnotationsLoadedPromise();

      const page = documentViewer.getDocument().getPageInfo(1);
      this.updateViewerDimensions(page.height)

      if (this.fileExtensionValue === 'pdf') {
        this.processPDF(annotationManager);
      } else {
        this.processDOCX(documentViewer);
      }
    });
  }

  updateViewerDimensions(height) {
    const offset = 100;
    const viewerElement = document.getElementById('tax_return_pdf_preview');
    viewerElement.style.height = `${height + offset}px`;
    const manualFields = document.getElementById('manual_fields');
    manualFields.style.height = `${height + offset * 2}px`;
  }

  processPDF(annotationManager) {
    const fieldManager = annotationManager.getFieldManager();
    let pdfFields = [];

    const typeMap = {
      'Tx': 'text',
      'Btn': 'button',
      'Ch': 'choice',
      'Sig': 'signature',
    }

    // Get all the fields in the PDF
    for (const field of fieldManager.getFields()) {
      pdfFields.push({
        name: field.name,
        type: typeMap[field.type],
      });
    }

    console.group("Available PDF Fields:")
    console.table(pdfFields);
    console.groupEnd();

    const formValueObject = Object.keys(this.fieldValuesValue).reduce((obj, key) => {
      // Find the field in the PDF with the same name as the key in the fieldValuesValue
      const field = fieldManager.getField(key);
      if (!field) return console.error(`Field "${key}" not found in PDF: ${this.urlValue}`);

      // Set the value of the PDF field with the value from the fieldValuesValue
      field.setValue(this.fieldValuesValue[key]);

      // Initialize obj if not set
      if (typeof obj === "undefined") { obj = Object(); }

      obj[key] = field.getValue();

      return obj;
    }, {});
  }

  async processDOCX(documentViewer) {
    const doc = documentViewer.getDocument();
    documentViewer.updateView();

    await doc.applyTemplateValues(this.fieldValuesValue);
  }
}
