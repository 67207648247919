const activeSelector = "[aria-selected='true']"

import { Controller } from "@hotwired/stimulus"

export default class Multiselect extends Controller {
  static targets = [
    "hidden",
    "list",
    "search",
    "preview",
    "dropdown",
    "item",
    "addable",
    "inputContainer"
  ]

  static values = {
    items: Array,
    selected: Array,
    addableUrl: String,
    disabled: { type: Boolean, default: false },
    updateDelay: Number,
    updateUrl: String,
    batchId: Number,
    states: Array
  }

  connect() {
    this.hiddenTarget.insertAdjacentHTML("afterend", this.template)
    if (this.selectedValue.length) this.selectedValueChanged()
    this.searchLocal = this.debounce(this.searchLocal.bind(this), 300)
    this.enhanceHiddenSelect()
    this.markInitialSelected();
  }

  markInitialSelected() {
    const updatedSelectedValue = [...this.selectedValue, ...this.statesValue];
    
    this.selectedValue = updatedSelectedValue;
    this.selectedValue.forEach(selectedItem => {
      this.checkItem(selectedItem.value)
    });  
  }
  
  sendUpdateRequest() {
    const excludedStates = this.selectedValue.map(item => item.value)
    const body = {
      financial_data_import_batch_file: {
        excluded_states: excludedStates
      },
      batch_id: this.batchIdValue
    };

    fetch(this.updateUrlValue, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': Rails.csrfToken()
      },
      body: JSON.stringify(body)
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response;
    })
  }
  
  enhanceHiddenSelect() {
    Object.defineProperty(this.hiddenTarget, "values", {
      get: () => {
        if (this.selectedValue.length <= 0) return []

        return this.selectedValue.map(item => item.value)
      }
    })
  }

  searchLocal() {
    this.dropdownTarget.classList.add("multiselect__dropdown--open")
    if (this.searchTarget.value === "") {
      let theRestOfTheItems = this.itemsValue.filter(x => !this.selectedValue.map(y => y.value).includes(x.value))
      this.listTarget.innerHTML = this.selectedItems
      this.listTarget.insertAdjacentHTML("beforeend", this.items(theRestOfTheItems))
    }

    let searched = this.itemsValue.filter(item => {
      return item.text.toLowerCase().includes(this.searchTarget.value.toLowerCase())
    })

    let selectedSearched = this.selectedValue.filter(item => {
      return item.text.toLowerCase().includes(this.searchTarget.value.toLowerCase())
    })

    searched = searched.filter(x => !selectedSearched.map(y => y.value).includes(x.value))

    if (searched.length === 0 && this.addableUrlValue) {
      return this.listTarget.innerHTML = this.noResultsTemplate
    }

    if (searched.length === 0) this.dropdownTarget.classList.remove("multiselect__dropdown--open")
    this.listTarget.innerHTML = this.items(selectedSearched, true)
    this.listTarget.insertAdjacentHTML("beforeend", this.items(searched))
  }

  itemsValueChanged() {
    if (!this.hasListTarget) return

    if (this.itemsValue.length) {
      this.listTarget.innerHTML = this.items(this.itemsValue)
    } else {
      this.listTarget.innerHTML = this.noResultsTemplate
    }
  }

  selectedValueChanged() {
    if (!this.hasPreviewTarget) return

    while (this.hiddenTarget.options.length) this.hiddenTarget.remove(0)

    if (this.selectedValue.length > 0) {
      this.selectedValue.forEach(selected => {
        const option = document.createElement("option")
        option.text = selected.text
        option.value = selected.value
        option.setAttribute("selected", true)
        this.hiddenTarget.add(option)
      })

      if (!this.searchRemoteValue) {
        this.selectedValue.forEach(selected => {
          this.checkItem(selected.value)
        })
      }
    } else {
      this.inputContainerTarget.style.display = ""
      this.previewTarget.innerHTML = ""
    }

    this.element.dispatchEvent(new Event("multiselect-change"))
  }

  uncheckItem(value) {
    const itemToUncheck = this.listTarget.querySelector(`input[data-value="${value}"]`)

    if (itemToUncheck) itemToUncheck.checked = false
  }

  checkItem(value) {
    const itemToCheck = this.listTarget.querySelector(`input[data-value="${value}"]`)

    if (itemToCheck) itemToCheck.checked = true
  }

  toggleItem(input) {
    const item = {
      text: input.dataset.text,
      value: input.dataset.value
    }
    let newSelectedArray = this.selectedValue

    if (input.checked) {
      newSelectedArray.push(item)

      if (this.focusedItem) {
        this.focusedItem.closest("li").classList.remove("multiselect__focused")
        this.focusedItem.removeAttribute("aria-selected")
      }

      input.setAttribute("aria-selected", "true")
      input.closest("li").classList.add("multiselect__focused")
      this.element.dispatchEvent(new CustomEvent("multiselect-added", { detail: { item: item } }))
    } else {
      newSelectedArray = newSelectedArray.filter(selected => selected.value.toString() !== item.value)
      this.element.dispatchEvent(new CustomEvent("multiselect-removed", { detail: { id: item.value } }))
    }

    this.selectedValue = newSelectedArray

    const form = input.closest('form');
    if (form) {
      const hiddenInput = this.hiddenTarget;
      if (hiddenInput) {
        hiddenInput.innerHTML = '';
        this.selectedValue.forEach(selected => {
          const option = document.createElement("option");
          option.text = selected.text;
          option.value = selected.value;
          option.setAttribute("selected", true);
          hiddenInput.appendChild(option);
        });
      }
    }
  }

  onKeyDown(e) {
    const handler = this[`on${e.key}Keydown`]
    if (handler) handler(e)
  }

  onArrowDownKeydown = (event) => {
    const item = this.sibling(true)
    if (item) this.navigate(item)
    event.preventDefault()
  }

  onArrowUpKeydown = (event) => {
    const item = this.sibling(false)
    if (item) this.navigate(item)
    event.preventDefault()
  }

  onBackspaceKeydown = () => {
    if (this.searchTarget.value !== "") return
    if (!this.selectedValue.length) return

    const selected = this.selectedValue
    const value = selected.pop().value

    this.uncheckItem(value)
    this.selectedValue = selected
    this.element.dispatchEvent(new CustomEvent("multiselect-removed", { detail: { id: value } }))
  }

  onEnterKeydown = (e) => {
    if (this.focusedItem) this.focusedItem.click()
  }

  onEscapeKeydown = () => {
    if (this.searchTarget.value !== "") {
      this.searchTarget.value = ""
      return this.searchLocal()
    }
  }

  onDeleteKeydown = () => {
    if (this.searchTarget.value !== "") {
      this.searchTarget.value = ""
      return this.searchLocal()
    }
  }

  sibling(next) {
    const options = this.itemTargets
    const selected = this.focusedItem
    const index = options.indexOf(selected)
    const sibling = next ? options[index + 1] : options[index - 1]
    const def = next ? options[0] : options[options.length - 1]
    return sibling || def
  }

  async addable(e) {
    e.preventDefault()
    const query = this.searchTarget.value

    if (query === "" || this.itemsValue.some(item => item.text === query)) return

    const response = await fetch(this.addableUrlValue, {
      method: "POST",
      body: JSON.stringify({ addable: query })
    })
    if (response.ok) {
      const addedItem = await response.json()

      this.addAddableItem(addedItem)
    }
  }

  addAddableItem(addedItem) {
    this.itemsValue = this.itemsValue.concat(addedItem)
    this.selectedValue = this.selectedValue.concat(addedItem)
    this.searchTarget.value = ""
    this.element.dispatchEvent(new CustomEvent("multiselect-added", { detail: { item: addedItem } }))
  }

  navigate(target) {
    const previouslySelected = this.focusedItem
    if (previouslySelected) {
      previouslySelected.removeAttribute("aria-selected")
      previouslySelected.closest("li").classList.remove("multiselect__focused")
    }

    target.setAttribute("aria-selected", "true")
    target.closest("li").classList.add("multiselect__focused")
    target.scrollIntoView({ behavior: "smooth", block: "nearest" })
  }

  get focusedItem() {
    return this.listTarget.querySelector(activeSelector)
  }

  focusSearch() {
    this.inputContainerTarget.style.display = ""
    this.searchTarget.focus()
  }

  addableEvent() {
    document.dispatchEvent(new CustomEvent("multiselect-addable"))
  }

  clearAll() {
    this.selectedValue = [];

    this.itemTargets.forEach(item => {
      item.checked = false;
    });

    while (this.hiddenTarget.options.length > 0) {
      this.hiddenTarget.remove(0);
    }

    this.element.dispatchEvent(new Event("multiselect-clear-all"));
  }

  get template() {
    return `
      <div class="max-w-sm rounded overflow-hidden shadow-lg px-4 bg-white border-gray-500 general-container mt-4">
        <div class="multiselect__container" data-multiselect-target="container" data-action="click->focus->multiselect#focusSearch" tabindex="0" data-turbo-cache="false">
          <div class="multiselect__preview" data-multiselect-target="preview">
          </div>
          <div class="multiselect__input-container" data-multiselect-target="inputContainer">${this.inputTemplate}</div>
        </div>
        <div style="position: relative;">
          <div class="multiselect__dropdown" data-multiselect-target="dropdown">
            <ul class="multiselect__list" data-multiselect-target="list">
              ${this.allItems}
            </ul>
          </div>
        </div>
        <div class="pt-2 relative flex justify-between">
          <button name="button" type="button" class="text-sm text-blue-800 h-8" data-action="click->multiselect#clearAll">Clear All</button>
          <button name="button" type="button" class="btn btn-primary h-8" data-action="click->multiselect#sendUpdateRequest multiselect-toggle#toggle">Apply</button>
        </div>
      </div>
    `
  }

  get noResultsTemplate() {
    if (!this.addableUrlValue) return `<div class="multiselect__no-result">${this.element.dataset.noResultsMessage}</div>`
    return `
      <div class="multiselect__no-result">
        <span class="multiselect__addable-button" data-action="click->multiselect#addableEvent">
          ${this.element.dataset.addablePlaceholder}
        </span>
      </div>
    `
  }

  get inputTemplate() {
      return `
        <input type="text" class="multiselect__search" placeholder="${this.element.dataset.placeholder}"
               data-multiselect-target="search" ${this.disabledValue === true ? 'disabled' : ''}
               data-action="multiselect#searchLocal keydown->multiselect#onKeyDown">
      `
  }

  items(items, selected = false) {
    const checked = selected ? "checked" : ""
    let itemsTemplate = ""

    items.forEach(item => itemsTemplate += this.itemTemplate(item, checked))

    return itemsTemplate
  }

  get selectedItems() {
    return this.items(this.selectedValue, true)
  }

  get allItems() {
    return this.items(this.itemsValue)
  }

  itemTemplate(item, selected = "") {
    return `
      <li>
        <label>
          <input type="checkbox" ${ selected } data-value="${item.value}" data-text="${item.text}"
          data-action="multiselect#checkBoxChange" data-multiselect-target="item" tabindex="-1">
          <span>${item.text}</span>
        </label>
      </li>
    `
  }

  checkBoxChange(event) {
    event.preventDefault()
    this.searchTarget.focus()
    this.toggleItem(event.currentTarget)
  }

  debounce(fn, delay) {
    let timeoutId = null

    return (...args) => {
      const callback = () => fn.apply(this, args)
      clearTimeout(timeoutId)
      timeoutId = setTimeout(callback, delay)
    }
  }
}

export { Multiselect }
