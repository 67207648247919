import { Controller } from "@hotwired/stimulus";
import { get } from "@rails/request.js";

export default class extends Controller {
  static targets = ["stateField", "countyField", "cityField"];
  static values = {
    countiesUrl: String,
    citiesUrl: String,
    state: String
  };

  changeState(event) {
    const url = new URL(event.target.dataset.locationFieldCountiesUrlValue, window.location.origin)

    url.searchParams.append("state", event.target.selectedOptions[0].value);

    get(url.href, {responseKind: "turbo-stream"});
  }

  changeCounty(event) {
    const url = new URL(event.target.dataset.locationFieldCitiesUrlValue, window.location.origin)

    url.searchParams.append("state", this.stateFieldTarget.selectedOptions[0].value);
    url.searchParams.append("county", event.target.selectedOptions[0].value);

    get(url.href, {responseKind: "turbo-stream"});
  }

  changeCounties(event) {
    const url = new URL(event.target.dataset.locationFieldCitiesUrlValue, window.location.origin)

    url.searchParams.append("state", event.target.dataset.locationFieldStateValue);
    Array.from(event.target.selectedOptions).forEach(option => {
      url.searchParams.append("counties[]", option.value);
    });

    get(url.href, {responseKind: "turbo-stream"});
  }
}

