import { Controller } from '@hotwired/stimulus';

const ONE_MINUTE = 300_000;

export default class extends Controller {
  connect() {
    addEventListener("turbo:before-stream-render", (_e) => { this.setResetTimeout() })

    this.setResetTimeout()
  }

  disconnect() {
    if (this.previousTimeout) {
      clearTimeout(this.previousTimeout);
    }
  }

  setResetTimeout() {
    if (this.previousTimeout) {
      clearTimeout(this.previousTimeout);
    }

    this.previousTimeout = setTimeout(() => {
      window.location.reload();
    }, ONE_MINUTE)
  }
}
