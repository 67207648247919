import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="financial-data-import-date"
export default class extends Controller {
  static targets = ["year", "month"]
  static values = {
    path: String
  }

  updateMonthOptions() {
    const updateMonthOptionsPath = this.pathValue

    const requestData = {
      year: parseInt(this.yearTarget.value),
      month: parseInt(this.monthTarget.value)
    };

    fetch(updateMonthOptionsPath, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'text/vnd.turbo-stream.html',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      },
      body: JSON.stringify(requestData),
    })
    .then(response => response.text())
    .then(html => {
      Turbo.renderStreamMessage(html);
    })
  }
}
