import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['content', 'chevron'];

  toggle(event) {
    const { id } = event.currentTarget.dataset;
    const contentElements = this.contentTargets.filter((el) => el.classList.contains(`expand_${id}`));
    const chevron = this.chevronTargets.find((el) => el.dataset.id === id);

    if (contentElements.length > 0 && chevron) {
      contentElements.forEach((content) => {
        const contentTargetId = content.id;
        
        content.classList.toggle('hidden');

        if (contentTargetId) { 
          document.querySelectorAll(`.expand_${contentTargetId}`).forEach((el) => el.classList.toggle('hidden', true));
        }
      });
      chevron.classList.toggle('rotate-180');
    }
  }
}
