import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="custom-rules"
export default class extends Controller {
  static targets = [
    "stateSelect", "countySelect", "citySelect", "zipSelect",
    "countyCheckbox", "cityCheckbox", "zipCheckbox",
    "nextButton"
  ];

  connect() {
    this.checkSelections();
    this.toggleCountySelection();
    this.toggleCitySelection();
    this.toggleZipSelection();
  }

  toggleCountySelection() {
    if (this.countyCheckboxTarget.checked) {
      this.countySelectTarget.querySelector('#county_field').removeAttribute('disabled')
      this.countySelectTarget.classList.remove("hidden")
      this.countySelectTarget.querySelector('#county_field').required = "required";
    } else {
      this.countySelectTarget.querySelector('#county_field').setAttribute('disabled', 'disabled')
      this.countySelectTarget.classList.add("hidden");
      this.countySelectTarget.querySelector('#county_field').required = false;
      this.disableCitySelect();
      this.disableZipSelect();
    }
  }

  toggleCitySelection() {
    if (this.cityCheckboxTarget.checked) {
      this.citySelectTarget.querySelector('#city_field').removeAttribute('disabled')
      this.citySelectTarget.classList.remove("hidden");
      this.citySelectTarget.querySelector('#city_field').required = "required";
    } else {
      this.citySelectTarget.querySelector('#city_field').required = false;
      this.disableCitySelect();
      this.disableZipSelect();
    }
  }

  toggleZipSelection() {
    if (this.zipCheckboxTarget.checked) {
      this.zipSelectTarget.querySelector('#zip_field').removeAttribute('disabled')
      this.zipSelectTarget.classList.remove("hidden");
    } else {
      this.disableZipSelect();
    }
  }

  checkSelections() {
    if (this.hasStateSelectTarget) {
      if (this.stateSelectTarget.value) {
        this.enableNextBtn()
      } else {
        this.disableNextBtn()
      }
    }
  }

  enableNextBtn(event) {
    this.nextButtonTarget.disabled = false
    this.nextButtonTarget.classList.remove("bg-gray-300", "color-grayish-syntax");
    this.nextButtonTarget.classList.add("bg-yellow-syntax", "color-navy-syntax");
  }

  disableNextBtn(event) {
    this.nextButtonTarget.disabled = true
    this.nextButtonTarget.classList.add("bg-gray-300", "color-grayish-syntax")
    this.nextButtonTarget.classList.remove("bg-yellow-syntax", "color-navy-syntax")
  }


  disableCitySelect() {
    this.citySelectTarget.querySelector('#city_field').setAttribute('disabled', 'disabled')
    this.citySelectTarget.classList.add("hidden");
    this.cityCheckboxTarget.checked = false
  }

  disableZipSelect() {
    this.zipSelectTarget.querySelector('#zip_field').setAttribute('disabled', 'disabled')
    this.zipSelectTarget.classList.add("hidden");
    this.zipCheckboxTarget.checked = false
  }
}
