// Reconnect ActionCable after switching accounts

import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ["parentCompanySelector"]

  roleChange(event) {
    const checkbox = event.currentTarget
    if (checkbox.id.includes('admin') && checkbox.checked) {
      this.parentCompanySelectorTarget.classList.toggle('hidden')
    }
  }
}
