import { Controller } from '@hotwired/stimulus';
import oneschemaImporter from '@oneschema/importer';

export default class extends Controller {
  static targets = ['button', 'customParams'];
  static values = {
    uploadUrl: String,
    createUrl: String,
    entityId: Number,
    parentCompanyId: Number,
    templateKey: String,
    clientId: String,
    railsEnv: String,
  };

  connect() {
    this.buttonTarget.addEventListener('click', this.handleButtonClick);
  }

  disconnect() {
    if (this.hasButtonTarget) {
      this.buttonTarget.removeEventListener('click', this.handleButtonClick);
    }
  }

  handleButtonClick = async () => {
    try {
      const token = await this.generateToken();
      this.initiateImport(token);
    } catch (error) {
      console.error('Token Generation Error: ', error);
    }
  };

  initiateImport(token) {
    const importerInstance = oneschemaImporter(this.configureImporter(token));
    importerInstance.launch();

    importerInstance.on('success', this.onImportSuccess).on('error', this.onImportError);
  }

  onImportSuccess = (context) => {
    this.startImport(context).then((data) => {
      window.location.href = data.success_url;
    });
  };

  onImportError = (message) => {
    console.log(message);
  };

  configureImporter(token) {
    return {
      clientId: this.clientIdValue,
      templateKey: this.templateKeyValue,
      importConfig: { type: 'local', metadataOnly: true },
      userJwt: token,
      devMode: this.railsEnvValue !== 'production',
      styles: this.uploaderStyles(),
    };
  }

  async startImport(data) {
    const entityId = this.entityIdValue;
    const parentCompanyId = this.parentCompanyIdValue;

    const response = await fetch(this.createUrlValue, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': this.csrfToken,
      },
      body: JSON.stringify({
        ...data,
        ...this.customParams,
        ...{
          entity_id: entityId,
          parent_company_id: parentCompanyId,
        },
      }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error ${response.status}`);
    }

    return await response.json();
  }

  uploaderStyles() {
    return {
      position: 'fixed',
      top: '0',
      left: '0',
      width: '100vw',
      height: '100vh',
      zIndex: '1000',
    };
  }

  generateToken = async () => {
    try {
      const response = await fetch(this.uploadUrlValue, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': this.csrfToken,
        },
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(`HTTP error ${response.status}: ${errorData?.message}`);
      }

      return (await response.json())?.token;
    } catch (error) {
      console.error('Error generating token:', error);
      throw error;
    }
  };

  get customParams() {
    return this.customParamsTargets.reduce((params, target) => {
      const paramName = target.dataset.paramName;
      const value = target.value;
      return { ...params, [paramName]: value };
    }, {});
  }

  get csrfToken() {
    return document.querySelector("meta[name='csrf-token']")?.content;
  }
}
