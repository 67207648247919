import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ["field", "submit"];

  initialize() {
    this.data.set("initial", this.formState());
  }

  connect() {
    this.initializeFields();
    this.resetInitialState();
    this.checkForChanges();
  }

  initializeFields() {
    this.fieldTargets.forEach(field => {
      const defaultValue = field.dataset.default;

      if (field.type === 'checkbox') {
        field.checked = defaultValue === 'true';
      } else {
        field.value = defaultValue;
      }
    });
  }

  resetInitialState() {
    this.data.set("initial", this.formState());
  }

  afterSubmit() {
    this.resetInitialState();
    this.checkForChanges();
  }

  statesAreEqual() {
    return this.data.get("initial") === this.formState();
  }

  checkForChanges() {
    this.submitTargets.forEach((target) => {
      target.disabled = this.statesAreEqual();
    });
  }

  formState() {
    return JSON.stringify(this.fieldTargets.map(field => {
      if (field.type === 'checkbox') {
        return field.checked;
      }
      return field.value;
    }));
  }
}
