import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="filing-calendar"
export default class extends Controller {
  static targets = ['showEntityStateFields', 'showEdiFields', 'editEntityStateFields', 'editEdiFields'];

  connect() {
    this.toggleElements(this.showEntityStateFieldsTargets, this.editEntityStateFieldsTargets);
    this.toggleElements(this.showEdiFieldsTargets, this.editEdiFieldsTargets);
  }

  showEntityStateFields() {
    this.toggleElements(this.showEntityStateFieldsTargets, this.editEntityStateFieldsTargets);
  }

  showEdiFields() {
    this.toggleElements(this.showEdiFieldsTargets, this.editEdiFieldsTargets);
  }

  editEntityStateFields() {
    this.toggleElements(this.editEntityStateFieldsTargets, this.showEntityStateFieldsTargets);
  }

  editEdiFields() {
    this.toggleElements(this.editEdiFieldsTargets, this.showEdiFieldsTargets);
  }

  toggleElements(visibleElements, hiddenElements) {
    visibleElements.forEach(element => {
      element.classList.remove('hidden');
    });
    hiddenElements.forEach(element => {
      element.classList.add('hidden');
    });
  }
}
