import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["checkbox", "button"];

  toggle() {
    if (this.checkboxTarget.checked){
      this.enable();
    } else {
      this.disable();
    }
  }

  enable() {
    this.buttonTarget.disabled = false
    this.buttonTarget.classList.remove("bg-gray-300", "color-grayish-syntax");
    this.buttonTarget.classList.add("bg-yellow-syntax", "color-navy-syntax");
  }

  disable() {
    this.buttonTarget.disabled = true
    this.buttonTarget.classList.remove("bg-yellow-syntax", "color-navy-syntax");
    this.buttonTarget.classList.add("bg-gray-300", "color-grayish-syntax");
  }
}
