import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="entity-subcategory-select"
export default class extends Controller {
  static targets = ["systemFieldSelect", "amountFieldDisplay"];

  connect() {
    this.systemFieldSelectTargets.forEach((select) => {
      if (!select.value || select.value === "") {
        this.setValidationMessage(select);
      }
    });
  }

  selectionChanged(event) {
    this.regularCategorySelectionChanged(event.target);
  }

  selectionChangedVertical(event) {
    this.verticalMappingSelectionChanged(event.target);
  }

  regularCategorySelectionChanged(select) {
    const index = select.dataset.index;
    const isMiscSubcategory = /miscellaneous_subcategory/.test(select.value);
    const systemFieldSelect = this.systemFieldSelectTargets.find(target => target.dataset.index === index);

    if (isMiscSubcategory) {
      systemFieldSelect.classList.remove("!hidden");
      this.setValidationMessage(systemFieldSelect);
    } else {
      systemFieldSelect.classList.add("!hidden");
      this.removeValidationMessage(systemFieldSelect);
    }
  }

  verticalMappingSelectionChanged(select) {
    const index = select.dataset.index;
    const isMiscSubcategory = /miscellaneous_subcategory/.test(select.value);
    const systemFieldSelect = this.systemFieldSelectTargets.find(e => e.dataset.index === index);
    const amountFieldDisplay = this.amountFieldDisplayTargets.find(e => e.dataset.index === index);

    if (isMiscSubcategory) {
      systemFieldSelect.classList.add("!hidden");
      amountFieldDisplay.classList.remove("hidden");
    } else {
      systemFieldSelect.classList.remove("!hidden");
      amountFieldDisplay.classList.add("hidden");
    }
  }

  setValidationMessage(select) {
    if (!select.value || select.value === "") {
      systemFieldSelect.required = true;
      select.setCustomValidity("Identify data file column name for any misc fields.");
    } else {
      this.removeValidationMessage(select);
    }
  }

  removeValidationMessage(select) {
    systemFieldSelect.required = false;
    select.setCustomValidity("");
  }
}
