import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

// Connects to data-controller="financial-data-totals-view"
export default class extends Controller {
  static values = { currentPageUrl: String, overallUrl: String }

  change(event) {
    const url = event.target.value === "overall" ? this.overallUrlValue : this.currentPageUrlValue;

    get(url, { responseKind: "turbo-stream" });
  }
}
