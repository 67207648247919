import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="devise-redirection"

// This controller is used to redirect the user to the next page after a successful devise form submission.
// Devise form redirects would have a turbo stream content type, which conflicts with endpoints that handle both 
// HTML and Turbo Stream.
export default class extends Controller {
  connect() {
    this.element.addEventListener("turbo:submit-end", (event) => {
      this.next(event)
    })
  }

  next(event) {
    if (event.detail.success && event.detail.fetchResponse.response.redirected) {
      Turbo.visit(event.detail.fetchResponse.response.url)
    }
  }
}
