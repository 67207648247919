import { Controller } from "@hotwired/stimulus";
import { get } from "@rails/request.js";

export default class extends Controller {
  connect(){
    this.url = new URL(this.data.get("url"), window.location.origin)
  }

  toggle(event){
    const asMasterCompany = event.currentTarget.value === "true";
    this.url.searchParams.set("as_master_company", asMasterCompany);
    this.updateSteps();
  }

  updateSteps() {
    get(this.url.href, {responseKind: "turbo-stream"});
  }
}
