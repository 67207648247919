import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['saveButton', 'form', 'input'];

  connect() {
    this.inputTargets.forEach((input) => {
      input.addEventListener('keyup', this.onKeyUp);
    });
  }

  onKeyUp = () => {
    this.enableSaveButton();
    this.enablePageLeavePrevention();
  }

  enableSaveButton() {
    this.saveButtonTarget.disabled = false;
  }

  enablePageLeavePrevention() {
    document.querySelector('body').dataset.preventPageLeaveEnabledValue = true;
  }

  submitForm() {
    this.formTarget.submit();
  }
}
