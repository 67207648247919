import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

// Connects to data-controller="eager-loader"
export default class extends Controller {
  static values = { src: String };  

  connect() {
    const src = this.srcValue;

    if (!!src.trim()) { 
      get(src, { responseKind: "turbo-stream" });
    }
  }
}
