import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['modal', 'stayButton', 'leaveButton'];
  static values = { enabled: Boolean };

  navigationHandled = false;

  enabledValueChanged() {
    this.enabledValue ? this.addEventListeners() : this.removeEventListeners();
  }

  addEventListeners() {
    window.addEventListener('beforeunload', this.handleBrowserNavigation);
    document.addEventListener('turbo:before-fetch-request', this.handleTurboNavigation);
    document.addEventListener('turbo:before-visit', this.handleTurboNavigation);
  }

  removeEventListeners() {
    window.removeEventListener('beforeunload', this.handleBrowserNavigation);
    document.removeEventListener('turbo:before-fetch-request', this.handleTurboNavigation);
    document.removeEventListener('turbo:before-visit', this.handleTurboNavigation);
  }

  handleTurboNavigation = async (event) => {
    if (['FORM', 'TURBO-FRAME'].includes(event.target.tagName)) {
      return;
    }

    if (!this.navigationHandled) {
      this.navigationHandled = true;

      event.preventDefault();

      this.openModal(event);

      await this.continueNavigation().then(() => {
        window.location = event.detail.url;
      }).catch(() => {
        this.closeModal(event);
      });

      this.navigationHandled = false;
    }
  }

  async continueNavigation() {
    return new Promise((resolve, reject) => {
      this.stayButtonTarget.addEventListener('click', () => {
        reject();
      });

      this.leaveButtonTarget.addEventListener('click', () => {
        resolve();
      });
    });
  }

  handleBrowserNavigation = (event) => {
    if (!this.navigationHandled) {
      this.navigationHandled = true;

      event.preventDefault();
      event.returnValue = '';

      this.navigationHandled = false;
    }
  }

  openModal(event) {
    this.application.getControllerForElementAndIdentifier(this.modalTarget, 'modal').open(event);
  }

  closeModal(event) {
    this.application.getControllerForElementAndIdentifier(this.modalTarget, 'modal').close(event);
  }
}
